.colorPrimary {
    color: #007bff;
}
.colorPrimaryDark {
    color: #0062cc;
}
.colorAccent {
    color: #ffc107;
}
.colorMain {
    color: #008dbf;
}
.colorBackgroundButton {
    color: #edeef0;
}
.colorBackgroundButtonPressed {
    color: #dddddd;
}
.colorBackgroundButtonFocused {
    color: #dfdfdf;
}
.button_primary_color_default {
    color: #007bff;
}
.button_primary_color_active {
    color: #0062cc;
}
.button_primary_color_focus {
    color: #0069d9;
}
.button_warning_color_default {
    color: #ffc107;
}
.button_warning_color_active {
    color: #d39e00;
}
.button_warning_color_focus {
    color: #e0a800;
}
.button_error_color_default {
    color: #dc3545;
}
.button_error_color_active {
    color: #bd2130;
}
.button_error_color_focus {
    color: #c82333;
}
.button_success_color_default {
    color: #28a745;
}
.button_success_color_active {
    color: #1e7e34;
}
.button_success_color_focus {
    color: #218838;
}
.color_shiny_red {
    color: #ff0000;
}
.color_red {
    color: #FF3737;
}
